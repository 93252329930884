import Background from './components/Background'
import Login from './components/Login';
function App() {
  return (
    <div>
      <Background>
      </Background>
      <Login></Login>
    </div>
  );
}

export default App;
